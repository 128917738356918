<template>
<div ref="modalPortContact" class="phone-modal phone-modal-port-search with-nav" :style="{ opacity: isPortContactModalOpen ? 1 : 0, bottom: modalPortContactHeight + 'px'}" :class="{active: isPortContactModalOpen}">
	<span class="modal-line"></span>
	<div class="modal-title-bar">
		<div class="icon-wrap">
			<ContactIcon style="width: 25px; height: auto;" class="icon contact-icon" />
			<span>Contact our team</span>
		</div><!-- icon wrap -->
		<GreyCloseIcon @click="togglePortContactModal" style="width: 31px; height: 31px;" class="icon close" />
	</div><!-- modal title bar -->
	<div class="section-gap">
		<div class="info-card">
			<span class="eyebrow">Contact Information</span>
			<div class="info-card-wrap">
				<div v-if="port.telephone" class="row">
					<p class="p-strong col">Telephone</p>
					<p class="col"><a class="tel" :href="'tel:' + getPhoneNumber">{{ port.telephone }}</a></p>
				</div>
				<div v-if="port.opening_times" class="row">
					<p class="p-strong col">Opening Times</p>
					<p class="col">{{ port.opening_times }}</p>
				</div>
				<div v-if="port.address || port.location" class="row">
					<p class="p-strong col">Address</p>
					<p v-if="port.address" class="col" v-html="port.address">{{ port.address }}</p>
                    <p v-else class="col">{{ port.location }}</p>
				</div>
			</div>
		</div><!-- info card -->
	</div>
	<div class="section-gap none-bottom card-wrap">
        <Card v-if="online"
            link="https://www.missiontoseafarers.org/contact-a-chaplain"
            class="card-block-blue"
            icon="ChatIcon"
            category="Flying Angel"
            title="Speak to the flying angel"
            text="Our interactive digital assistant"
        />
		<Card
			routeName="Requests"
			class="card-block-green-filled"
			icon="SendIcon"
			category="Requests"
			title="Make a Request"
			text="Order welfare services from this port"
		/>
	</div><!-- card wrap -->
</div><!-- modal -->
</template>

<script>
import Card from '@/components/Card.vue';
import GreyCloseIcon from '@/assets/close-grey.svg';
import ContactIcon from '@/assets/contact.svg';
export default {
name: 'PortContactOverlay',
	props: {
		subtitle: String,
		title: String,
		icon: String
	},
	data () {
		return {
			modalPortContactHeight: null,
			phoneNumberTrimmed: ''
		};
	},
	components: {
		Card,
		GreyCloseIcon,
		ContactIcon
	},
	computed: {
		matchHeightModal () {
			return '-' + this.$refs.modalPortContact.clientHeight;
		},
        isPortContactModalOpen () {
            return this.$store.state.isPortContactModalOpen;
        },
        port () {
            return this.$store.state.port;
        },
        getPhoneNumber () {
        	var phoneNo = this.port.telephone;
			phoneNo = phoneNo.replace(/\s+/g, '');
			return phoneNo;
        },
        online () {
            return window.navigator.onLine;
        }
	},
	methods: {
        togglePortContactModal () {
            this.$store.commit('PORT_CONTACT_TOGGLE');
            this.modalPortContactHeight = '-' + this.$refs.modalPortContact.clientHeight;
        }
    },
    mounted () {
		this.modalPortContactHeight = '-1000';
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.modal-title-bar {
		.icon-wrap {
			svg {
				g, path {
					stroke-width: 2.5;
					stroke: $blue_dark;
				}
			}
			span {
				color: $blue_dark;
			}
		}
	}
</style>
