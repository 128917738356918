<template>
	<div class="port relative-container">
		<Nav />
		<span class="overlay" :class="{active: isMenuOpen || isPortContactModalOpen || isPortCheckInConfirmModalOpen || isPortCheckInSuccessModalOpen || isAccessModalOpen }"></span>
		<div class="header header-standard">
            <HeaderNavBar back v-if="myPorts" star @click.native="togglePort"/>
            <HeaderNavBar back v-else starOutline @click.native="togglePort"/>
		</div><!-- header -->
		<div class="header-image port-image blue big">
            <img v-if="port.image_url" class="bg" :alt="port.name" :src="port.image_url"/>
        </div><!-- header image -->
		<div class="port-hero-content">
			<div class="info">
				<h1 class="port-name">{{ port.name }}</h1>
				<span class="port-region">{{ port.country }}</span>
			</div><!-- info -->
			<div class="time">
				<span class="time-text">Local time</span>
                <span class="time-actual">{{ date | moment('timezone', port.timezone, 'h:mm:ss A') }}</span>
			</div>
		</div><!-- port hero content -->
		<div class="content-wrap content-wrap-alt content-wrap-nav">
			<SectionTitle
				icon="AnchorIcon"
				subtitle="Welcome to the port of"
				:title="port.name"
				colour="blue"
			/>
			<div v-if="port.active" class="section-gap card-wrap">
				<Card
                    @click.native="$store.commit('PORT_CONTACT_TOGGLE', port)"
					class="card-block-blue"
					icon="ContactIcon"
					category="Contact"
					title="Contact"
					text="Speak to the seafarers' centre within the port"
				/>
				<Card
					@click.native="isAuthenticated ? $store.commit('PORT_CHECK_IN_CONFIRM_TOGGLE', port) : $store.state.isAccessModalOpen = true;"
					class="card-block-orange"
					icon="CheckInIcon"
					category="check-in"
					title="Check-in"
					text="Tap to check in to the centre, and view wifi details"
				/>
			</div><!-- card wrap -->
			<div class="text-block section-gap">
				<p class="no-space"><strong>Caring for seafarers in {{ port.name }}</strong></p>
				<p class="no-space">{{ port.description }}</p>
			</div>
			<div v-if="port.active" class="section-gap card-wrap">
				<Card
					routeName="Requests"
					class="card-block-green card-block-full"
					icon="SendIcon"
					category="Requests"
					title="Make a Request"
					text="Order welfare services from this port"
				/>
			</div><!-- card wrap -->
			<div v-if="port.facilities" class="section-gap">
				<div class="section-title-wrap">
					<h2 class="section-title">Facilities</h2>
				</div>
				<div class="tag-wrap" v-dragscroll>
					<span v-for="facility in port.facilities" :key="facility">{{ facility }}</span>
				</div><!-- tag wrap -->
			</div>
			<div class="section-gap">
				<Map
                    :latitude="port.latitude"
                    :longitude="port.longitude"
                    :location="port.location ? port.location : port.name"
                    :address="port.address ? port.address : port.name"
                />
			</div>
			<div class="section-gap-alt">
				<Button v-if="myPorts" icon="StarIcon" class="btn btn-red btn-icon btn-home-white btn-icon-right" text="Remove from My Ports" @click.native="togglePort($event, true)"></Button>
                <Button v-else icon="StarEmptyIcon" class="btn btn-blue btn-icon btn-home-white btn-icon-right" text="Add to My Ports" @click.native="togglePort($event, true)"></Button>
			</div>
		</div>

        <div ref="modalAccess" class="phone-modal with-nav" style="bottom: -600px;" :class="{active: isAccessModalOpen}">
            <span class="modal-line"></span>
            <div class="modal-title-bar">
                <div class="icon-wrap">
                    <RegisterIcon style="width: 27px; height: 27px;" class="icon register-icon" />
                    <span>Create your profile</span>
                </div><!-- icon wrap -->
                <GreyCloseIcon @click="closeAccessModal" style="width: 31px; height: 31px;" class="icon close" />
            </div><!-- modal title bar -->
            <div class="modal-message-block wide">
                <ProfileIcon class="profile-icon" style="width: 50px; height: 50px;" />
                <h3>Please create a profile to access this area.</h3>
                <p>This app will always be free, but creating a profile will give you greater access to the app.</p>
            </div>
            <Button routeName="/create-profile" icon="RegisterIcon" class="btn btn-green btn-icon btn-icon-white btn-icon-small btn-icon-right" text="Okay, I'll create a profile"></Button>
            <Button @click.native="openLoginScreen" icon="LoginIcon" class="btn btn-slate btn-icon btn-icon-white btn-icon-small btn-icon-right" text="I already have a profile"></Button>
            <a @click="closeAccessModal()" class="text-link text-link-grey">I'll create a profile another time &raquo;</a>
        </div><!-- modal -->

		<Menu />
		<PortContactOverlay />
		<PortCheckInConfirmOverlay />
		<PortCheckInSuccessOverlay />
	</div>
</template>
<script>
	import HeaderNavBar from '@/components/HeaderNavBar.vue';
	import Nav from '@/components/Nav.vue';
	import Button from '@/components/Button.vue';
	import Menu from '@/components/Menu.vue';
	import Card from '@/components/Card.vue';
	import Map from '@/components/Map.vue';
	import PortContactOverlay from '@/components/PortContactOverlay.vue';
	import PortCheckInConfirmOverlay from '@/components/PortCheckInConfirmOverlay.vue';
	import PortCheckInSuccessOverlay from '@/components/PortCheckInSuccessOverlay.vue';
	import SectionTitle from '@/components/SectionTitle.vue';
	import UserService from '@/services/UserService';
    import PortService from '@/services/PortService';
    import RegisterIcon from '@/assets/register.svg';
    import ProfileIcon from '@/assets/profile.svg';
    import GreyCloseIcon from '@/assets/close-grey.svg';
	export default {
		name: 'Port',
		components: {
			Nav,
			HeaderNavBar,
			Menu,
			Card,
			Map,
			Button,
			SectionTitle,
			PortContactOverlay,
			PortCheckInConfirmOverlay,
			PortCheckInSuccessOverlay,
            RegisterIcon,
            ProfileIcon,
            GreyCloseIcon
		},
		data () {
	        return {
                date: new Date(),
                port: {},
                myPorts: false,
                checkInOverlayConfirm: false
	        };
	    },
	    methods: {
            getPort () {
                return PortService.getPort(this.$route.params.id).then((response) => {
                    this.port = response.data;
                    if (response.data.user) {
                        this.myPorts = response.data.user;
                    }
                    if (this.isAuthenticated) {
                        UserService.isPortFavourite(this.$route.params.id).then((response) => {
                            this.myPorts = response.data;
                        });
                    }
                });
            },
            togglePort (event, ignore) {
                if (ignore || (event.target.classList.contains('star-icon') || event.target.parentElement.classList.contains('star-icon'))) {
                    if (this.isAuthenticated) {
                        UserService.togglePort(this.port.id).then((response) => {
                            this.myPorts = response.data;
                        });
                    } else {
                        this.$store.state.isAccessModalOpen = true;
                    }
                }
            },
            openLoginScreen () {
                this.$router.push({ name: 'Home', params: { showLoginModal: true } });
            },
            closeAccessModal () {
                this.$store.state.isAccessModalOpen = false;
            }
        },
	    computed: {
	    	isPortContactModalOpen () {
	            return this.$store.state.isPortContactModalOpen;
	        },
	        isPortCheckInConfirmModalOpen () {
	            return this.$store.state.isPortCheckInConfirmModalOpen;
	        },
	        isPortCheckInSuccessModalOpen () {
	        	return this.$store.state.isPortCheckInSuccessModalOpen;
	        },
			isMenuOpen () {
	            return this.$store.state.isMenuOpen;
	        },
            isAuthenticated () {
                return this.$store.getters.isAuthenticated;
            },
            isAccessModalOpen () {
                return this.$store.state.isAccessModalOpen;
            }
        },
		mounted () {
            this.getPort();
            setInterval(() => {
                this.date = new Date();
            }, 1000);
        }
	};
</script>
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.port-image img.bg {
		opacity: 0.35;
	}
	.port-hero-content {
		position: relative;
		text-align: left;
		display: flex;
	    justify-content: space-between;
	    padding: 78px 0 28px 0;
	    margin: 0 28px;
	    align-items: flex-end;
	    .info {
	    	.port-name {
	    		font-size: 26px;
	    		line-height: 31px;
	    		color: $white;
	    		display: block;
	    	}
	    	.port-region {
	    		font-size: 26px;
	    		letter-spacing: -0.65px;
	    		line-height: 31px;
	    		color: $white;
	    		opacity: 0.8;
	    		display: block;
	    	}
	    }
	    .time {
	    	span {
	    		display: block;
	    		font-size: 13px;
	    		line-height: 18px;
	    		color: $white;
	    		&.time-actual {
	    			font-weight: $weight_600;
	    		}
	    	}
	    }
	}
</style>
