<template>
<div class="map">
	<div class="map-wrap">
        <iframe
            width="600"
            height="200"
            style="border:0"
            referrerpolicy="no-referrer-when-downgrade"
            allowfullscreen
            :src="gmapembed"
        >
        </iframe>
    </div><!-- map wrap -->
	<div class="map-info">
		<span class="eyebrow">Address</span>
		<p v-html="address">{{ address }}</p>
	</div><!-- map info -->
</div><!-- map -->
</template>

<script>
export default {
	name: 'Map',
	props: {
		text: String,
        latitude: Number,
        longitude: Number,
        location: String,
        address: String
	},
	components: {

	},
    computed: {
        gmapembed () {
            return 'https://www.google.com/maps/embed/v1/place?key=AIzaSyBFQHyWNfhhxGQGQoX40pcH2E0ZHBXXoI0&q=' + encodeURIComponent(this.location) + '';
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.map {
		position: relative;
		text-align: left;
		.map-wrap {
			height: 175px;
			border-radius: 12px 12px 0 0;
			background: $slate;
            overflow: hidden;
		}
		.map-info {
			padding: 18px 17px 18px;
			background: $white;
			border-radius: 0 0 12px 12px;
			border: 1px solid $snow;
			border-top: 0;
			box-shadow: 5px 15px 15px 5px $snow;
			.eyebrow {

			}
			p {
				color: $dark;
				font-size: 13px;
				letter-spacing: -0.38px;
				line-height: 16px;
				font-weight: $weight_600;
			}
		}
	}
</style>
